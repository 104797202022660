import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo"
import CTA from "../components/cta";

import fintech from "../images/backrounds/fintech.png";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="FinTech"
        description="Let our experts in the fintech industry user their expertise to make your processes more efficient and secure today!"
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${fintech})`,
          }}
        >
          <div className="hero-title  bg-primary-alternative-transparent">
            <h1 className="text-white">financial technology partnership</h1>
            <p className="text-white">
              Providing design, development, and maintenance and operations for the unique needs of the financial tech
              sector.
            </p>
            <CTA
              href="#begin"
              text="Tell Us About Your Project"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2>we can help you build your fintech product</h2>
              <p>Financial tech companies face a unique set of challenges as business and technology becomes more
                complex and users expect better and faster experiences.</p>
              <p>Whether you are building a loan funnel, a new mortgage, financing or lending product, or connecting
                your product to outside financial tools, 729 can do it. For the last 15 years, we have been helping
                companies in the financial space to develop websites, mobile, and programmatic solutions. We understand
                the complex needs of the financial community including compliance, security, and data privacy. As a
                leading Systems Integrator, 729 Solutions can also help your product integrate with almost any other
                technology.</p>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-6 mt-5">
              <h3>we can help with any of the following:</h3>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">Data Integrations</span></li>
                <li><span className="text-dark">Loan Search funnels</span></li>
                <li><span className="text-dark">Best Practices for Financial Products</span></li>
                <li><span className="text-dark">API Integrations</span></li>
                <li><span className="text-dark">Big Data Processing</span></li>
                  <li><span className="text-dark">Mortgage Back End functionality</span></li>
                  <li><span className="text-dark">Single Sign On</span></li>
                  <li><span className="text-dark">PCI Compliance</span></li>
                  <li><span className="text-dark">Knowledge Based Authentication (KBA)</span></li>
                  <li><span className="text-dark">Building APIs, SDKs, etc.</span></li>
              </ul>
            </div>
            <div className="col-lg-6 mt-5">
              <h3>some of the products we have built sdk / apis or integrated with:</h3>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">Encompass by Ellie Mae</span></li>
                <li><span className="text-dark">Roostify</span></li>
                <li><span className="text-dark">Ziplogix</span></li>
                <li><span className="text-dark">Solidifi</span></li>
                <li><span className="text-dark">Optimal Blue</span></li>
                <li><span className="text-dark">Maxwell</span></li>
                <li><span className="text-dark">Velocify</span></li>
                <li><span className="text-dark">Inffrd</span></li>
                <li><span className="text-dark">Box</span></li>
                <li><span className="text-dark">Docusign</span></li>
                <li><span className="text-dark">Jumio</span></li>
                <li><span className="text-dark">IdentityX</span></li>
                <li><span className="text-dark">Zendesk</span></li>
              </ul>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <h3>how can 729 help with my fintech needs?</h3>
              <p>As a high velocity full stack software development shop with deep systems integrations roots, we’ve got
                you covered for back end, front end and connecting tools. Leverage our deep experience building custom
                tools for the financial industry to build a better product.</p>
              <p>Starting a green field project and need full consulting from the ground up? We’ve got your back. Our
                team of UsI and UX experts can walk you through persona mapping, user flow diagrams, content planning
                and project structure from start to finish. Whatever you don’t know, we probably can advise you on.
                Whatever new and exciting solution you’re building, we can help you go faster!</p>

            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <h3>we guarantee to:</h3>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">Set up a scoping call within three days</span></li>
                <li><span className="text-dark">Provide an estimate within a week</span></li>
                <li><span className="text-dark">Start work within two weeks</span></li>
                <li><span className="text-dark">Offer Competitive pricing and do exceptional work</span></li>
              </ul>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <p>Trust in 729’s 15 years of success and experience as a well-vetted software development and DevOps
                shop.</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <p className="text-center">Still not sure?</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <p className="text-center">
                <CTA text="See More of What We Do" href="/services/" className="btn btn-primary"></CTA>
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <h3 className="text-center">Build better financial technology faster and safer with 729!</h3>
            </div>
          </div>
        </div>
      </section>

      <section
        className="d-flex bg-pattern-trihex bg-dark"
        style={{
          backgroundSize: "250px",
        }}
      >
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="text-white mb-3">contact us today</h2>
              <p className="text-white mb-5">Please click the button below to fill out the form. We will contact you as soon as possible to schedule
                a call.</p>
              <CTA text="Make a Time to Talk" href="/contact-us/" className="btn btn-primary"></CTA>
            </div>
          </div>
        </div>
      </section>
      </Layout>
  );
};

export default Index;
